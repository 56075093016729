@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

:root {
  --background: #304473;
  --primary: #cbd0dc;
  --secondary: #6b7b9f;
  --primary-dark: #0b1d46;
  --secondary-dark: #01081a;
}

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: var(--primary);
}

.App {
    width: 100vw;
    height: 100vh;
}

.content {
  max-width: 600px;
  margin: auto;
  padding: 5%;
}

body {
  margin: 0;
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
