/* --------------------------------- */
/* Styles for The Fox and the Grapes */
/* --------------------------------- */

.DuckFeed {
  position: absolute;
  max-width: 600px;
  height: 400px;
  background-color: var(--primary-dark);
  cursor: url(./img/ducky.png), auto; /*ie*/
  position: relative;
  overflow: hidden;
}

.GameStats {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2.5% 0;
}

.cereal {
  background: url("./img/cereal.png") no-repeat center;
  height: 50px;
  width: 50px;
  position: absolute;
  transition: all 0.2s cubic-bezier(0, 0.65, 0.03, 1.2);
  -moz-transition: all 0.2s cubic-bezier(0, 0.65, 0.03, 1.2);
  -o-transition: all 0.2s cubic-bezier(0, 0.65, 0.03, 1.2);
}

.HighScore {
  margin-top: 5%;
}

.scores {
    list-style: none;
}

.scores li {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@keyframes shake {
  0% {
    -webkit-transform: translate(0, 0) rotate(0);
  }
  1% {
    -webkit-transform: translate(-1px, -2px) rotate(-10deg);
  }
  2% {
    -webkit-transform: translate(-3px, 0) rotate(30deg);
  }
  3% {
    -webkit-transform: translate(0px, 2px) rotate(0);
  }
  4% {
    -webkit-transform: translate(1px, -1px) rotate(30deg);
  }
  5% {
    -webkit-transform: translate(-1px, 2px) rotate(-10deg);
  }
  6% {
    -webkit-transform: translate(-3px, 1px) rotate(0);
  }
  7% {
    -webkit-transform: translate(2px, 1px) rotate(-10deg);
  }
  8% {
    -webkit-transform: translate(-1px, -1px) rotate(10deg);
  }
  9% {
    -webkit-transform: translate(2px, 2px) rotate(0);
  }
  10% {
    -webkit-transform: translate(0, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 5s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
