.NavBar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid var(--primary);
}
.NavBar h1,
.NavBar h2,
.NavBar h3 {
  color: var(--primary);
}
.NavBar .links {
  margin-left: auto;
}
.NavBar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  color: var(--secondary);
}
.NavBar a:hover {
  color: var(--primary);
}
