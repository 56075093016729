.Button {
  color: var(--background);
  background-color: var(--primary);
  border-radius: 10%;
  font-size: large;
  cursor: pointer;
  position: relative;
  border: 2px solid var(--primary);
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.Button:hover {
  color: var(--primary);
  background-color: transparent;
  border: 2px solid var(--primary-dark);
}
.Button:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.Button:before {
  display: block;
  position: absolute;
  background-color: var(--primary-dark);
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: var(--secondary);
  background: var(--primary-dark);
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
