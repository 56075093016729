@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
:root {
  --background: #304473;
  --primary: #cbd0dc;
  --secondary: #6b7b9f;
  --primary-dark: #0b1d46;
  --secondary-dark: #01081a;
}

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #cbd0dc;
  color: var(--primary);
}

.App {
    width: 100vw;
    height: 100vh;
}

.content {
  max-width: 600px;
  margin: auto;
  padding: 5%;
}

body {
  margin: 0;
  background-color: #304473;
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Home{
    text-align: center;
}

.Home > h1,
.Home > h2,
.Home > h3 {
    color: var(--color-primary-1)
}
.Header {
  border-bottom: 1px solid var(--primary);
  padding: 0 0 5%;
}

.Button {
  color: var(--background);
  background-color: var(--primary);
  border-radius: 10%;
  font-size: large;
  cursor: pointer;
  position: relative;
  border: 2px solid var(--primary);
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
.Button:hover {
  color: var(--primary);
  background-color: transparent;
  border: 2px solid var(--primary-dark);
}
.Button:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
.Button:before {
  display: block;
  position: absolute;
  background-color: var(--primary-dark);
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: var(--secondary);
  background: var(--primary-dark);
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

/* --------------------------------- */
/* Styles for The Fox and the Grapes */
/* --------------------------------- */

.DuckFeed {
  position: absolute;
  max-width: 600px;
  height: 400px;
  background-color: var(--primary-dark);
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAP8SURBVEiJtZZbTFxFGMf/3zm75yy7XHZhyxJoWSsLUq5yLS1UBWrFmFbExKQhtI2J9K1NTYhpY5OqjTFeUmPEmJi+tA9qeSFqamODtmhjW6BgIQu1lEKslMvCsnD20j2X8QFsYTms+NB5OzPz/37znflm/kOMMTzOZlhrgIicmency8RbyjSNPalpWu/YeOBHVcUlxph/vQCKzICI+MpisdUWy/Y3viSKpeVV5Ewzo3/Ih1+7PMGszXHnmlq6zno8wY7/DSAiU+FT4h8t+5TMxjqVYCkHBOcqUfOxHs/NwbkPrvbNfPJfAG75R06G0H6iOZzVWKcSAMDg0BV9ebLYnmQT37bGxdSsG0BEuQUu1NQ/t5QRFwtwJn0RR2hr3WZNthtPrwtARJSXIX56pDFsBIDLPQD4hKhCcwyPnZXJdiLKiApwmM3bXHab++9JrbYgE4Am4s+BGmC8CdDWLDIAwM4qR6wzNebFqIBkq+V037HXs2tKYskkAAjl4o2Ko8BsLbCgvwf/tpI8G0SR3xFtjkHRtAuH2i4mj0wrSYwBZO4FUt8D+HggISYqwH3bx+YW1OtRMxicmHnzTNfN3T6/MnXnHgAwIK4TMJ8HWDgq4Ief709PeYIXI/uJKJ2IqomI4wBAltnvI/eD1R+dEZRH0zQgPBYV0N3v9QMYWApKFpNhj8sZ272vwXntxOGc9s2bzJ893EXGmLuiwOSe9qJgg22p88EIIGauCSjLTxRn58JXtrji4wqzrZaGujTboQOZCdZ4IwDg6+//2rWiTBSVvhkaXQZQ54HwPUDYqAv4/N2iVFnRUo0GTndcFDghAsCsocjfHugG+AQEwmYc/XgIfYMLsCWYdQNuSjHgncMuJFoFAICB54wrAIIB9VvzIlRMBqTf0PhWEup2VeHDk9shCvrn49v2Lly65kbDC2lLC9bkFbkZjMwRb1ktvOGWIGsiDu5/Zs3gAODxeGE08IvrYoA/oEqRGSh6Qu88UFb0xJqBASAYCuOVWgEpiYuHc3wyCBCNrcjggUySrIPYUQT09PRiQQrpBr9y/Q727H0fPCRw3OJF/F3HeNC3ILev8INcl+nggd3qFy1Nyqqy6L3F4fhXdqQ4NqCiPB/pGx0YGBzFjb5bMBu9OHV8KywWEQAgBRSU1XfcHRqez1rlaMU5ptunjoRdz5boe/XsPHC1nzA2YUR+hozCwkLEJT46K/6AguebOieH7/pfm/IGO/Us01KaYzq//Wm18tVqmS/NAcz6tgDE5AGmLVBUhuFRCRcuTwRazw6Pz/mU5mlv8BdAx5OXgSqKssW9goBaVYXurSeFeCksU5iIZAY2ODMT+sknqW2MMe1hnMf9bPkHGlV+El/vZooAAAAASUVORK5CYII=), auto; /*ie*/
  position: relative;
  overflow: hidden;
}

.GameStats {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2.5% 0;
}

.cereal {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAhQAAAIUB4uz/wQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAARgSURBVEiJ7ZPLa9xVFMe/997fe2Yyk8kkTSaT17Rpw9Q0TY0PbKWlLnyA/4K6UXDlXhGtKLgRhFIXiW2tRSkUFB8BtRQsWC1o2vRhH9iYR/PoZJLJZGZ+85vf87ioM0lLBCsuXHjgcBfn3O/33vO5lxER8iOsFcBCZHCvC6Krvll8Rdt34Ux+hD3GNeNVNbXlGbmp1ZJ2fBvCfYb051oGYFPgq0pLxwDXQl/lR1g7gG/0dH9EbU/DLxX8+xUHAA4A8RepDOCEPTsBABCRWERKJA8ySYkorV0AAK+U//wfG+AA4xTgiJvPXnFuTwNEEIr2vAg1gAkBN3877y/cere2qXSINZWPRpo3ElwYZsbSMGvD2JBcN8g1o4VxnIkM7s0wSZovjZ2Gu7II3ywurv40eskvr8bV3v6xmoir4DRPdWS9S08u2z889AYALA+zvpXDyrFoT6YQ3/PsvF32Hq4beBKKACrkOVxOtCf1rbsQWGWQ5yS07u07tM5tIAZn3UGz5NhMamiKK8nNbzqnBrYyhmGlreM5o3dA5roB8vyZukHbS1QBw6f23E0AgNQQh9TQCCYrXNnUAQDwiysn6/IMR5zsDMh3wTgHDO0YgMfVVO+dG67kJrUnLt9aY3CSiQD4zC3kx+3534EggAg3QhgNYIzDyc7knMVbh2v6EuE8OP/OunkxINcGhPQoAEjhGLxCruLMT72Pk506ADAiQu4D1ipkLEQe3E8ALVkTvzYHVRPwnUWmhvJa59Y+KZqoigdGdQDIj7BxrTszoLZ1mdbUNdsvr8b90gq4bvyiJtO71PYt3L09uUfdO352PQOTnCqT463Nob5dCKomAtdN6D2ZPrV9MxAE1XUjWiDHggjHQuHtj8TJsQAAclNySE/3cyYrCCxM3cWAGD6uzt5hICKNkKJNYLJaZ+CVlj+p6RMw4mRnQJ4LMA61fQsAQEvdWb2Vxd/0p8bn1hgcYJIgjPpm8Wx1+gbIcyFCUQgjAiJCdX5ixs0unFi7AK4zIX1ZuXHeCaomRDgKAOBGA5zcXMGemzyIUztDGzEAE6Jg3bwY880SAs/NCiNi6j2ZtIjEbJH5Wqsz6MkMqMm0XZ2+bnr5bNyvlMA144LWuW2nmkwzZ25it7rvwo8cAEwDBQDFwK5AiiZixrYhBLYF+F6L3rM9rWzqBHluuT4iwkxQNSGMiBrqG4pT4AEAlObUoNbVx8A5fMubqI+o+wWqguEju8YgHAXXwxZXNKa0pGr/4HjdgOFDNzsLch2AMcjNKQcA1I46g2vG05ezawwAsADv+YWlL6qTV4lcB0yIWa6HQYEPd2n+mlqg12q9iRhG4fuHzBtjll8pgQm5CABcC8NbzWUDa/Xlui4RYX3kj7IOzoxBAr3OZfUdpanr59qLuDeyx1lIdrCbK+G3qVJ+S+/tX9L2Xzp3VxMRbZjLwzj3V7V7c2kY308ehbZRjW90sn8z/jf4LxsQLv5tEcKV7ml4G9X+AM57LFp9bE9iAAAAAElFTkSuQmCC) no-repeat center;
  height: 50px;
  width: 50px;
  position: absolute;
  transition: all 0.2s cubic-bezier(0, 0.65, 0.03, 1.2);
  -moz-transition: all 0.2s cubic-bezier(0, 0.65, 0.03, 1.2);
  -o-transition: all 0.2s cubic-bezier(0, 0.65, 0.03, 1.2);
}

.HighScore {
  margin-top: 5%;
}

.scores {
    list-style: none;
}

.scores li {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@keyframes shake {
  0% {
    -webkit-transform: translate(0, 0) rotate(0);
  }
  1% {
    -webkit-transform: translate(-1px, -2px) rotate(-10deg);
  }
  2% {
    -webkit-transform: translate(-3px, 0) rotate(30deg);
  }
  3% {
    -webkit-transform: translate(0px, 2px) rotate(0);
  }
  4% {
    -webkit-transform: translate(1px, -1px) rotate(30deg);
  }
  5% {
    -webkit-transform: translate(-1px, 2px) rotate(-10deg);
  }
  6% {
    -webkit-transform: translate(-3px, 1px) rotate(0);
  }
  7% {
    -webkit-transform: translate(2px, 1px) rotate(-10deg);
  }
  8% {
    -webkit-transform: translate(-1px, -1px) rotate(10deg);
  }
  9% {
    -webkit-transform: translate(2px, 2px) rotate(0);
  }
  10% {
    -webkit-transform: translate(0, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 5s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.NavBar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid var(--primary);
}
.NavBar h1,
.NavBar h2,
.NavBar h3 {
  color: var(--primary);
}
.NavBar .links {
  margin-left: auto;
}
.NavBar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  color: var(--secondary);
}
.NavBar a:hover {
  color: var(--primary);
}

